import {
  Paper,
  Text,
  Container,
  Space,
  Title,
  Group,
  Card,
  rem,
  SimpleGrid,
  Button,
  Center,
  LoadingOverlay,
  Grid,
  Popover,
  Radio,
  Stack
} from "@mantine/core";
import {
  IconCalendar,
  IconChartPie,
  IconClockRecord,
  IconDoorEnter,
  IconDoorExit,
  IconFilter,
  IconDownload,
} from "@tabler/icons-react";
import React, { useState, useEffect, useContext, useRef  } from "react";
import { DatePickerInput } from "@mantine/dates";
import axios from "axios";
import { useForm } from "@mantine/form";
import { ConfigContext } from "../../Context/configContext";
import { useTitle } from "../hooks/useTitle";

function AnalyticsPage(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId] = useState<any | null>(localStorage.getItem("activeDomainId"));
  const [analyticsData, setAnalyticsData] = useState<any>(null);
  const [isDataAvailable, setIsDataAvailable] = useState(false); // Initialize to false
  const [loading, setLoading] = useState(false);
  const initialRef = useRef(false);
  const [selectedRange, setSelectedRange] = useState("");
  const [popoverOpened, setPopoverOpened] = useState(false);

  useTitle("Analyze Consent Records");

  // Custom function to format dates as "DD-MM-YYYY"
  const formatDate = (date: Date | null) => {
    if (!date) return "";
    return date
      .toLocaleDateString("en-GB") // Formats the date as DD/MM/YYYY
      .replace(/\//g, "-"); // Replaces slashes with dashes for DD-MM-YYYY format
  };

  const handleDateRangeChange = (filter) => {
    const today = new Date();
    let startDate: Date | null = null;
    let endDate: Date | null = null;

    if(filter !== 'Specific date range') {
      switch (filter) {
        case "Today":
          startDate = today;
          endDate = today;
          break;
        case "Yesterday":
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 1);
          endDate = new Date(today);
          break;
        case "Last 7 Days":
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 7);
          endDate = today;
          break;
        case "Last 30 Days":
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 30);
          endDate = today;
          break;
        default:
          startDate = form.values.startDate;
          endDate = form.values.endDate;
      }
    }

    form.values.startDate = startDate;
    form.values.endDate = endDate;
    setSelectedRange(filter);

    if(filter !== 'Specific date range') {
      fetchAnalyticsData();
    }
  };

  // Form validation for the date pickers
  const form = useForm({
    initialValues: {
      startDate: null as Date | null,
      endDate: null as Date | null,
    },
    validate: {
      startDate: (value) => (!value ? "Start date is required" : null),
      endDate: (value) => (!value ? "End date is required" : null),
    },
  });

  const formatAverageTimeToConsent = (timeInSeconds) => {
    const roundedTime = Math.round(timeInSeconds);
    
    if (roundedTime < 60) {
      return `${roundedTime} sec`;
    } else {
      const minutes = Math.floor(roundedTime / 60);
      const seconds = roundedTime % 60;
      return `${minutes} min ${seconds} sec`;
    }
  };

  const data = [
    {
      title: "Total Opt-ins",
      subTitle: '',
      description: analyticsData?.totalOptIns.toString(),
      icon: <IconDoorEnter className="primary" size={70} stroke={1}/>,
      color: "var(--mantine-primary-color-filled)",
      bgColor: "#FFE5B4",
    },
    {
      title: "Total Opt-outs",
      subTitle: '',
      description: analyticsData?.totalOptOuts.toString(),
      icon: <IconDoorExit size={70} color="blue" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
    },
    {
      title: "Average Time to Consent",
      subTitle: '',
      description: analyticsData?.averageTimeToConsent ? formatAverageTimeToConsent(analyticsData.averageTimeToConsent): "N/A",      
      icon: <IconClockRecord size={70} color="orange" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
    },
    {
      title: "Most Consented Cookie",
      subTitle: 'cookie that users most likely give consent to: ',
      description: analyticsData?.mostAcceptedCookie.toString(),
      icon: <IconChartPie size={70} color="blue" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
    },
    {
      title: "Least Consented Cookie",
      subTitle: 'cookie that users least likely give consent to: ',
      description: analyticsData?.leastAcceptedCookie.toString(),
      icon: <IconChartPie size={70} color="orange" stroke={1} />,
      color: "#4469bc",
      bgColor: "#f2f4fa",
    },
  ];

  const fetchAnalyticsData = async () => {
    if (domainId) {
      setLoading(true);
      try {
        const response = await axios.post(`${config!.apiBaseUrl}/domain/analytics`, {
          domainId,
          startDate: formatDate(form.values.startDate),
          endDate: formatDate(form.values.endDate),
        });
        setAnalyticsData(response.data);
        setIsDataAvailable(response.data.chartData.length > 0);
      } catch (error) {
        console.error("Error fetching analytics data", error);
        setIsDataAvailable(false);
      } finally {
        setLoading(false);
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }; 
  
  useEffect(() => {
    if(!initialRef.current) {
      initialRef.current = true;
      fetchAnalyticsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId, form.values.startDate, form.values.endDate]);
  
  const handleFilterClick = () => {
    if (form.validate().hasErrors === false) {
      setTimeout(() => {
        fetchAnalyticsData();
        console.log("Delayed for 1 second.");
      }, 1000);
    }
  };

  const handleDownloadCSV = async () => {

    try {
      const response = await fetch(`${config!.apiBaseUrl}/domain/consents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token") ||""}`,
        },
        body: JSON.stringify({
          domainId,
          startDate: formatDate(form.values.startDate),
          endDate: formatDate(form.values.endDate),
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to download CSV: ${response.statusText}`);
      }

      // Convert the response to a blob and create a download link
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'consents.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link); // Remove the link after download
      window.URL.revokeObjectURL(url); // Clean up the object URL

    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  return (
    <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
    <>
      <Container fluid>
        <Title mt="lg" mb="lg" order={2}>
          Analytics
        </Title>
        <Card withBorder shadow="sm" radius="md">
          <Card.Section withBorder inheritPadding mt="sm" pb="md">
            <Grid>
              <Grid.Col span={{ base: 12, md: 12 }}>
                  <Group justify="right">
                    <Group>
                      <Popover 
                        width={350} position="right-end" withArrow shadow="md"  
                        opened={popoverOpened}
                        onClose={() => setPopoverOpened(false)}
                        closeOnClickOutside={false}
                      >
                        <Popover.Target>
                          <Button 
                            leftSection={
                              <IconFilter
                                style={{ width: rem(18), height: rem(18) }}
                                stroke={1.5}
                              />
                            } 
                            onClick={() => setPopoverOpened((o) => !o)}
                          >
                            Filter
                          </Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                        <Stack>
                          <Radio.Group
                            value={selectedRange}
                            onChange={handleDateRangeChange}
                          >
                            <Radio mt={10} value="Today" label="Today" />
                            <Radio mt={10} value="Yesterday" label="Yesterday" />
                            <Radio mt={10} value="Last 7 Days" label="Last 7 Days" />
                            <Radio mt={10} value="Last 30 Days" label="Last 30 Days" />
                            <Radio mt={10} value="Specific date range" label="Custom Range" />
                          </Radio.Group>
                          </Stack>
                          <Group>
                          {selectedRange === "Specific date range" && (
                              <>
                              <form onSubmit={form.onSubmit(() => handleFilterClick())}>
                                <Group>
                                <DatePickerInput mt={10}
                                  label="FROM"
                                  placeholder="Start Date"
                                  size="xs"
                                  leftSectionPointerEvents="none"
                                  rightSection={
                                    <IconCalendar
                                      style={{ width: rem(18), height: rem(18) }}
                                      stroke={1.5}
                                    />
                                  }
                                  value={form.values.startDate}
                                  onChange={(date) => form.setFieldValue("startDate", date)}
                                  error={form.errors.startDate}
                                />
                                <DatePickerInput mt={10}
                                  placeholder="End Date"
                                  label="TO"
                                  size="xs"
                                  leftSectionPointerEvents="none"
                                  rightSection={
                                    <IconCalendar
                                      style={{ width: rem(18), height: rem(18) }}
                                      stroke={1.5}
                                    />
                                  }
                                  value={form.values.endDate}
                                  onChange={(date) => form.setFieldValue("endDate", date)}
                                  error={form.errors.endDate}
                                />
                                </Group>
                                <Button size="xs" variant="outline" mt={10} type="submit">
                                  Filter
                                </Button>
                                </form>
                              </>
                          )}
                          </Group>
                        </Popover.Dropdown>
                      </Popover>
                      </Group>
                    <div>
                        {analyticsData?.chartData.length > 0 && (
                          <Button 
                            leftSection={
                              <IconDownload
                                style={{ width: rem(18), height: rem(18) }}
                                stroke={1.5}
                              />
                            }  
                            variant="outline"
                            onClick={() => handleDownloadCSV()}
                          >
                            Download CSV
                          </Button>
                        )}
                    </div>
                  </Group>
              </Grid.Col>
            </Grid>
          </Card.Section>

          <Card.Section withBorder inheritPadding mt="sm" pb="md">
            {isDataAvailable ? (
              <React.Fragment>
                <SimpleGrid cols={3}>
                  {data.map((stat, index) => (
                    <div key={index}>
                      <Paper shadow="sm" p="sm" withBorder bg="#F4F6FB">
                        <Text>{stat.title}</Text>
                        {stat.subTitle && (
                          <Text size="xs">{stat.subTitle}</Text>
                        )}
                        <Group justify="apart" mt={10}>
                          {stat.icon}
                          <div>
                            <Text fw={500} style={{fontSize: '25px'}} w={140}>
                              {stat.description}
                            </Text>
                          </div>
                        </Group>
                      </Paper>
                    </div>
                  ))}
                </SimpleGrid>
                <Space h="xl" />
              </React.Fragment>
            ) : (
              <Center>
                <Text>No Data Available</Text> 
              </Center>// Display message when no data
            )}
          </Card.Section>
        </Card>
        <Card shadow="sm" p="xl" mt="xl">
          <Text fw={700} mb={10}>
            User Consent Logging
          </Text>
          <Text size="xs">
            Cookies Consolidates all user consents into a single, centralized
            consent log. This ensures that if authorities request proof of
            user consents, you can promptly and efficiently provide the
            necessary documentation.
          </Text>
        </Card>
      </Container>
      </>
    </React.Fragment>
  );
}

export { AnalyticsPage };

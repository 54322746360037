import {
  Grid,
  Paper,
  Text,
  Container,
  Group,
  Badge,
  Card,
  ThemeIcon,
  SimpleGrid,
  Button,
  Title,
  Image,
  Stack,
  em
} from "@mantine/core";
import {
  IconCreditCard,
  IconAdjustmentsAlt,
  IconFileText,
  IconGavel,
  IconSettings,
  IconReportAnalytics,
  IconFileInfo,
  IconShieldLock,
  IconWorld,
  IconCookie,
  IconListDetails,
  IconSearch,
  IconMapPin
} from "@tabler/icons-react";
import { Link } from 'react-router-dom';
import React, { useState, useRef, useEffect, useContext } from "react";
import { ConsentService } from "../../services/consentService";
import { formatDate } from "../../utils/utils";
import { ConfigContext } from "../../Context/configContext";
import { Wizard } from "./consentV2/Wizard";
import dashBoardBg from "../../images/dashboard-bg.png";
import classes from './dashboad.module.css';
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { useMediaQuery } from '@mantine/hooks';

const arr = ['unclassifiedCookies', 'statisticsCookies', 'prefrenceCookies', 'marketingCookies'];

function DashboardPage(props): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [domainId] = useState<any | null>(localStorage.getItem('activeDomainId'));
  const initialized = useRef(false);
  const [lastScan, setLastScan] = useState<any>('Not Started Yet');
  const [totalScannedPages, setTotalScannedPages] = useState(0);
  const [totalCookies, setTotalCookies] = useState(0);
  const [totalCatgoires, setTotalCatgoires] = useState(0);
  const [isShowWizard, setIsShowWizard] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const consentService = new ConsentService(config!);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getDomainScanDataById();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  const getDomainScanDataById = () => {
    if(domainId) {
         const response = consentService.getDomainScanDataById(domainId);
         response.then((res) => {
            if(res?.data){
                const lastScanTimestamp = res?.data?.lastScan?.$date?.$numberLong;
                if (lastScanTimestamp) {
                  const theDate = new Date(parseInt(lastScanTimestamp));
                  setLastScan(formatDate(theDate)); // Correctly formatted scan date
                } else {
                  setLastScan("No scan date available");
                }

                if(res?.data?.totalPages) {
                  setTotalScannedPages(res?.data?.totalPages);
                }

                if(res?.data?.totalCookies) {
                  setTotalCookies(res?.data?.totalCookies);
                }   
                
                const totalCatgoiresCount = countCategories(res?.data, arr);
                setTotalCatgoires(totalCatgoiresCount);
            }
         });
    }
  };

  const countCategories = (response: Record<string, any>, arr: string[]) => {
    return arr.reduce((acc, key) => acc + (response[key] > 0 ? 1 : 0), 0);
  };
  
  const data = [
    {
      title: "Configuration Settings",
      description: "Manage the configuration settings for your banner and cookie consent.",
      icon: <IconAdjustmentsAlt />,
      urlLabel: "Configuration",
      link: "/account/configuration",
      color: "#4a90e2",
    },
    {
      title: "Privacy Policy Generator",
      description: "Set up the user interface of your privacy trigger.",
      icon: <IconShieldLock />,
      urlLabel: "Customize privacy",
      link: "/account/privacy-policy",
      color: "#f39c12",
    },
    {
      title: "Consent Records Management",
      description: "View your visitor consents and downloaded proof to demonstrate compliance with regulations.",
      icon: <IconFileText />,
      urlLabel: "View all data",
      link: "/account/consentRecords",
      color: "#4a90e2",
    },
    {
      title: "Consent Banner Customization",
      description: "Set up the user interface of your consent banner.",
      icon: <IconCreditCard />,
      urlLabel: "Customize Banner",
      link: "/account/consentBanners",
      color: "#f39c12",
    },
    {
      title: "Integration Options",
      description: "Implement the cookie CMP banner manually by adding the script tag.",
      icon: <IconSettings />,
      urlLabel: "Integration",
      link: "/integration",
      color: "#4a90e2",
    },
    {
      title: "Analytics Dashboard",
      description: "An interactive dashboard to track key performance indicators.",
      icon: <IconReportAnalytics />,
      urlLabel: "View Analytics",
      link: "/account/analytics",
      color: "#f39c12",
    },
  ];

  const handleGetStartedClick = () => {
    setIsShowWizard(true);
  };

  return (
    <>
     {!props?.user?.newUser && (
      <Container fluid>
        <Grid mt="xl">
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Card h={245} shadow="lg" radius="md" withBorder>
                    <SimpleGrid mt="md" cols={{ base: 1, sm: 2 }} spacing="lg" verticalSpacing="lg">
                      <Paper shadow="sm" p={20} className="snotes-1" radius="md" style={{ flex: 1, border:'1px solid #f0f8ff' }}>
                        <Group align="center">
                          <ThemeIcon className="primary"  size={40} radius="xl">
                            <IconWorld size="1.5rem" color="white" stroke={1.5} />
                          </ThemeIcon>
                          <div>
                            <Text size="sm">Domain</Text>
                            <Text size="xs" fw="700">{props && props?.currentConsentData && props?.currentConsentData[0]?.domain}</Text>
                          </div>
                        </Group>
                      </Paper>
                      <Paper shadow="sm" p={20} className="snotes-1" radius="md" style={{ flex: 1, border:'1px solid #f0f8ff' }}>
                        <Group align="center">
                          <ThemeIcon className="primary" size={40} radius="xl">
                            <IconCreditCard size="1.5rem" color="white" stroke={1.5} />
                          </ThemeIcon>
                          <div>
                            <Text size="sm">Domain Status</Text>
                            <Text size="xs" fw="700"c={props?.currentConsentData?.[0]?.status === 'Active' ? 'green' : 'orange'}>{props?.currentConsentData?.[0]?.status}</Text>
                          </div>
                        </Group>
                      </Paper>
                    </SimpleGrid>
                    <SimpleGrid mt="md" cols={{ base: 1, sm: 2 }} spacing="lg" verticalSpacing="lg">
                        <Paper shadow="sm" p={20} className="snotes-2" radius="md" style={{ flex: 1 }}>
                          <Group align="center">
                            <ThemeIcon className="primary" size={40} radius="xl">
                              <IconGavel color="white" size="1.5rem" stroke={1.5} />
                            </ThemeIcon>
                            <div>
                              <Text size="sm">Regulation</Text>
                              <Text size="xs" fw="700">GDPR/DPDPA</Text>
                            </div>
                          </Group>
                        </Paper>
                        <Paper shadow="sm" p={20} className="snotes-2" radius="md" style={{ flex: 1 }}>
                          <Group align="center">
                            <ThemeIcon className="primary" size={40} radius="xl">
                              <IconMapPin color="white" size="1.5rem" stroke={1.5} />
                            </ThemeIcon>
                            <div>
                              <Text size="sm">Targeted location</Text>
                              <Text size="xs" fw="700">INDIA</Text>
                            </div>
                          </Group>
                        </Paper>
                    </SimpleGrid>
                  </Card>
                </Grid.Col>

                <Grid.Col span={{ base: 12, md: 6 }}>
                  <Card h={245} shadow="lg" radius="md" withBorder>
                    <SimpleGrid mt="md" cols={{ base: 1, sm: 2 }} spacing="lg" verticalSpacing="lg">
                      <Paper shadow="sm" p={20} className="snotes-1" radius="md" style={{ flex: 1 }}>
                        <Group align="center">
                          <ThemeIcon className="primary" size={40} radius="xl">
                            <IconCookie size="1.5rem" color="white" stroke={1.5} />
                          </ThemeIcon>
                          <div>
                            <Text size="sm">Total Cookies</Text>
                            <Text size="xs" fw="700">{totalCookies}</Text>
                          </div>
                        </Group>
                      </Paper>
                      <Paper shadow="sm" p={20} className="snotes-1" radius="md" style={{ flex: 1 }}>
                        <Group align="center">
                          <ThemeIcon className="primary" size={40} radius="xl">
                            <IconListDetails size="1.5rem" color="white" stroke={1.5} />
                          </ThemeIcon>
                          <div>
                            <Text size="sm">Categories</Text>
                            <Text size="xs" fw="700">{totalCatgoires}</Text>
                          </div>
                        </Group>
                      </Paper>
                    </SimpleGrid>
                    <SimpleGrid mt="md" cols={{ base: 1, sm: 2 }} spacing="lg" verticalSpacing="lg">
                        <Paper shadow="sm" p={20} className="snotes-2" radius="md" style={{ flex: 1, border:'1px solid #fff8e1'}}>
                          <Group align="center">
                            <ThemeIcon className="primary" size={40} radius="xl">
                              <IconSearch size="1.5rem" color="white" stroke={1.5} />
                            </ThemeIcon>
                            <div>
                              <Text size="sm">Last scan</Text>
                              <Text size="xs" fw="700">{lastScan}</Text>
                            </div>
                          </Group>
                        </Paper>
                        <Paper shadow="sm" p={20} className="snotes-2" radius="md" style={{ flex: 1, border:'1px solid #fff8e1' }}>
                          <Group align="center">
                            <ThemeIcon className="primary"  size={40} radius="xl">
                              <IconFileInfo size="1.5rem" color="white" stroke={1.5} />
                            </ThemeIcon>
                            <div>
                              <Text size="sm">Pages scanned</Text>
                              <Text size="xs" fw="700">{totalScannedPages}</Text>
                            </div>
                          </Group>
                        </Paper>
                      </SimpleGrid>
                  </Card>
                </Grid.Col>
              </Grid>
                <Grid mt="lg">
                  {data.map((stat) => (
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }} key={stat.title}>
                      <Card
                        shadow="lg"
                        radius="md"
                        withBorder
                        style={{
                          transition: 'transform 0.3s',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          height: '300px',
                          '&:hover': { transform: 'scale(1.05)', boxShadow: '0 8px 16px rgba(0,0,0,0.2)' }
                        }}
                      >
                        <Card.Section p="lg" style={{ textAlign: 'center' }}>
                          <Badge
                            color={stat.color}
                            w={50}
                            h={50}
                            radius="xl"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto' }}
                          >
                            {stat.icon}
                          </Badge>
                          <Text fw={700} size="lg" mt="sm" style={{ color: '#333' }}>{stat.title}</Text>
                        </Card.Section>
                        <Card.Section  style={{ padding: '10px', textAlign: 'center' }}>
                          <Text size="sm" style={{ whiteSpace: "pre-line", color: '#555' }}>{stat.description}</Text>
                        </Card.Section>
                        <Card.Section  pb="md" style={{ padding: '20px', textAlign: 'center' }}>
                          <Link to={stat.link}>
                            <Button variant="outline" color={stat.color} style={{ width: '100%', marginTop: 'auto' }}>
                              {stat.urlLabel}
                            </Button>
                          </Link>
                        </Card.Section>
                      </Card>
                    </Grid.Col>
                  ))}
              </Grid>
          </Container>
        )}
        {props?.user?.newUser && (
          <>
          <Container style={{maxWidth: '100%', overflow:'hidden'}} pl={0} pr={0}>
            <div className={classes.wrapper}>
                <Grid align="center" gutter="lg" mt={isMobile?"xl":"0"}>
                  <Grid.Col span={{ base: 12, sm:12, md: 8, lg:8 }}>
                    <Stack pl={30}>
                      <Title order={1} style={{ fontSize: '2rem' }}>Welcome to Cookiex!</Title>
                      <Text size="md">
                        Your trusted partner in managing consent with ease and compliance.
                      </Text>
                      <Button mt={20} size="md" variant="filled" color="blue" style={{ width: 'fit-content' }} rightSection={<IconArrowNarrowRight />} onClick={handleGetStartedClick}>
                        Get Started
                      </Button>
                    </Stack>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Image src={dashBoardBg} alt="Illustration" className={classes.image}/>
                  </Grid.Col>
                </Grid>
              </div>
              <div style={{ backgroundColor: '#fff', padding:'2rem'}}>
                <Title order={2} style={{ marginBottom: '1rem' }}>
                  Key Features
                </Title>
                <Grid gutter="md" mt={30}>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Automated Cookie Scanning</Title>
                      <Text size="sm" mt={10}>
                        Regularly scan your website to identify and categorize cookies and tracking technologies.
                      </Text>
                    </Card>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Customizable Consent Banners</Title>
                      <Text size="sm" mt={10}>
                        Tailor consent notices to align with your brand and inform users about data collection practices.
                      </Text>
                    </Card>
                  </Grid.Col>
                  <Grid.Col span={{ base: 12, sm:12, md: 4, lg:4 }}>
                    <Card bg="#f5f5f5" padding="lg" radius="md" h={150}>
                      <Title order={4}>Consent Log Management</Title>
                      <Text size="sm" mt={10}>
                        Securely maintain detailed records of user consent decisions to ensure transparency.
                      </Text>
                    </Card>
                  </Grid.Col>
                </Grid>
              </div>
            </Container>
            {isShowWizard && (
              <Wizard user={props} />
            )}
          </>
        )}    
      </>
  );
}

export { DashboardPage };

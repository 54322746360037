import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import { LogInPage } from "../Pages/LogInPage";
import { RegisterSuccess } from "../Pages/RegisterSuccess";
import { PasswordSuccess } from "../Pages/PasswordSuccess";
import { ResetPassword } from "../Pages/ResetPassword";
import { UpdatePassword } from "../Pages/UpdatePassword";
import { Registration } from "../Pages/Registration";
import { Analytics } from "../Pages/account/Analytics";
import { Dashboard } from "../Pages/account/Dashboard";
import { Domains } from "../Pages/account/Domains";
import { Cookies } from "../Pages/account/Cookies";
import { CookieReport } from "../Pages/account/CookieReport";
import { ConsentBanners } from "../Pages/account/ConsentBanners";
import { ConsentRecords } from "../Pages/account/ConsentRecords";
import { Configuration } from "../Pages/account/Configuration";
import { PrivacyPolicy } from "../Pages/account/PrivacyPolicy";
import { Integration } from "../Pages/account/Integration";
import { Profile } from "../Pages/account/Profile";
import { Pricing } from "../Pages/account/Pricing";
import ProtectedRoute from "./ProtectedRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "", element: <LogInPage /> },
      { path: "login", element: <LogInPage /> },
      { path: "register", element: <Registration /> },
      { path: "verification", element: <RegisterSuccess /> },
      { path: "resetPassword", element: <ResetPassword /> },
      { path: "reset", element: <UpdatePassword /> },
      { path: "update", element: <PasswordSuccess /> },
      {
        path: "/account/analytics",
        element: (
          <ProtectedRoute>
            <Analytics />
          </ProtectedRoute>
        ),
      },
      {
        path: "/account/pricing",
        element: (
          <ProtectedRoute>
            <Pricing />
          </ProtectedRoute>
        ),
      },
      {
        path: "/account/integration",
        element: (
          <ProtectedRoute>
            <Integration />
          </ProtectedRoute>
        ),
      },
      {
        path: "/account/dashboard",
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "/account/domains",
        element: (
          <ProtectedRoute>
            <Domains />
          </ProtectedRoute>
        ),
      },
      {
          path: "/account/privacy-audits",
          element: (
            <ProtectedRoute>
              <Cookies />
            </ProtectedRoute>
          ),
      },
      {
        path: "/account/cookieReport",
        element: (
          <ProtectedRoute>
            <CookieReport />
          </ProtectedRoute>
        ),
      },
      {
          path: "/account/consentRecords",
          element: (
            <ProtectedRoute>
              <ConsentRecords />
            </ProtectedRoute>
          ),
      },
      {
          path: "/account/configuration",
          element: (
              <ProtectedRoute>
                <Configuration />
              </ProtectedRoute>
          ),
      },
      {
          path: "/account/consentBanners",
          element: (
            <ProtectedRoute>
              <ConsentBanners />
            </ProtectedRoute>
          ),
      },
      {
          path: "/account/privacy-policy",
          element: (
             <ProtectedRoute>
                <PrivacyPolicy />
             </ProtectedRoute>
          ),
      },
      {
          path: "/account/profile",
          element: (
              <ProtectedRoute>
                  <Profile />
              </ProtectedRoute>
          ),
      }
    ],
  },
]);

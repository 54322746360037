import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Text,
  Grid,
  Title,
  LoadingOverlay,
  Modal,
  Group,
  Button,
  Loader,
} from '@mantine/core';
import './pricing.css';
import arrow from "../../images/aero.svg";
import { PricingService } from "../../services/pricingService";
import { ConfigContext } from '../../Context/configContext';
import { useTitle } from '../hooks/useTitle';
import { showNotification } from '@mantine/notifications';
import { useLocation } from "react-router-dom"; // Import useLocation to get the query parameters

export const APP_URL = "https://app.cookiex.io";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
// Headings
export const PRICING_HEADINGS = {
  top: "Pricing",
  title: "Choose a Plan That Works For You",
  subtitle: "14-day free trial. No fee till your trial ends. Cancel anytime.",
};


const PricingScreen = (props) => {
  const { config } = useContext(ConfigContext);
  const pricingService = useMemo(() => new PricingService(config!), [config]);
  const [loading, setLoading] = useState(false);
  const [subscriptionLoader, setSubscriptionLoader] = useState(false);
  const [activeTab, setActiveTab] = useState<any>('monthly');
  const [plans, setPlans] = useState<any[]>([]);
  const initialRef = useRef(false);
  const query = useQuery();
  const domainId = query.get("domainId"); // Get the domainId from the query parameters
  const [opened, setOpened] = useState(false);
  const [shortUrl, setShortUrl] = useState('');

  useTitle("Pricing");

   const getPlans = useCallback(async () => {
      setLoading(true);
      try {
          const response = await pricingService.getPlans();
          console.log("API Response:", response); // Log the response
          setPlans(response.data);
      } catch (error) {
          console.error("Error fetching domains:", error);
          showNotification({ color: "red", message: "Failed to fetch Plans." });
      } finally {
          setLoading(false);
      }
  }, [pricingService]);

  useEffect(() => {
    if(!initialRef.current) {
      initialRef.current = true;
      getPlans();
    }
  }, [getPlans]);

  const onSelectPlan = useCallback(
    (planId) => {
      setOpened(true); // Open modal immediately
      setSubscriptionLoader(true); // Show loader
  
      setTimeout(() => {
        const fetchPlan = async () => {
          try {
            const payload = {
              planId,
              totalCount: activeTab === 'yearly' ? 12 : 1,
            };
  
            const result = await pricingService.savePlan(payload, domainId);

            if (result.shortUrl) {
              setShortUrl(result.shortUrl); // Set the short URL
              showNotification({ color: "green", message: "Plan selected successfully!" });
            } else {
              throw new Error("No shortUrl returned in response.");
            }
          } catch (error) {
            showNotification({ color: "red", message: "Failed to select plan. Please try again." });
            setOpened(false); // Close modal on failure
          } finally {
            setSubscriptionLoader(false); // Stop loader after delay
          }
        };
  
        fetchPlan(); // Call the async function
      }, 1000); // Simulated delay of 1 second
    },
    [activeTab, domainId, pricingService]
  );
  

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(shortUrl);
    showNotification({ color: "green", message: "URL copied to clipboard!" });
  };

  const handleOpenLink = () => {
    if (shortUrl) {
      window.open(shortUrl, '_blank', 'noopener,noreferrer');
    } else {
      showNotification({ color: "red", message: "Payment link is not available." });
    }
  };

  return (
    <React.Fragment>
      {loading && (
          <LoadingOverlay visible={true} zIndex={1000}   overlayProps={{ radius: 'sm', blur: 2 }} loaderProps={{ color: 'green', type: 'bars' }}/>
      )}
    <>
    <div className="container">
        <div className="heading f-heading text-center">
            <Title order={4} className="top-hd">{PRICING_HEADINGS.top}</Title>
            <Title className='h2' order={2}>{PRICING_HEADINGS.title}</Title>
            <Text size='md'>{PRICING_HEADINGS.subtitle}</Text>
        </div>
        <ul className={`pricing-tabs ${activeTab === 'monthly' ? '' : 'rmv-ofr'}`}>
          <li
            className={`pricing-tab-link ${activeTab === 'monthly' ? 'current' : ''}`}
            onClick={() => setActiveTab('monthly')}
          >
          Monthly
          </li>
          <li
            className={`pricing-tab-link ${activeTab === 'yearly' ? 'current' : ''}`}
            onClick={() => setActiveTab('yearly')}
          >
            Yearly
          </li>
        </ul>

        <div id={`pricing-tab-${activeTab}`} className="pricing-tab-content current">
          {plans[activeTab] && plans[activeTab].length > 0 ? (
          <Grid className="row pricing-cards">
            {plans[activeTab].map((plan, index) => (
              <Grid.Col key={index} className={`prc-col ${plan.badge ? 'special' : ''}`} span={{ base: 12, md: 6, lg: 3 }}>
                <div className="p-card">
                  <Title className="h4" order={4}>
                    {plan.category}
                  </Title>
                  <Text className="p">{plan.description}</Text>
                  <Title className="h3" order={3}>
                    {plan.price !== 0 ? plan.price : "\u00A0"} &nbsp;
                    <span className="sm">
                      {plan.price !== 0 ? (
                        plan.timeline
                      ) : (
                        <>
                          {"\u00A0 \u00A0"}
                          <br /> <br />
                        </>
                      )}
                    </span>
                  </Title>
                  <Button onClick={() => onSelectPlan(plan.planId)} className="get-started my-btn my-btn-2">
                    <img className="ero-img" src={arrow} alt="Get Started" /> &nbsp;
                    Get Started
                  </Button>
                  <ul className="tab-list">
                    {plan.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </Grid.Col>
            ))}
          </Grid>
         ) : (
          <Text className="no-plans">No plans available for {activeTab}.</Text>
        )}
        </div>
        <Modal withCloseButton={false} opened={opened} onClose={() => setOpened(false)} centered>
        <>
          {subscriptionLoader && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <Loader variant="bars" color='green' />
            </div>
            
          )}
          {!subscriptionLoader && (
            <div>
              <Title order={3} className="text-center">Complete Your Subscription</Title>
              <Text size="sm" className="text-center" mt={20} mb={20}>
                Use the link below to complete your payment:
              </Text>
              <Group justify="center" mt={20}>
                <Button variant="filled" color="green" onClick={handleOpenLink}>
                  Open Payment Link
                </Button>
                <Button variant="outline" color="blue" onClick={handleCopyUrl}>
                  Copy URL
                </Button>
              </Group>
            </div>
          )}
        </>
      </Modal>
      </div>
    </>
    </React.Fragment>
  );
};

export { PricingScreen };

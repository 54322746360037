import React, { useState, useEffect, useRef, useContext } from "react";
import { Image, Accordion, Center, Stack, Flex, Title, Card, Divider, Container, Text } from '@mantine/core';
import { ConsentService } from "../../services/consentService";
import logo from "../../images/logo.png";
import { ConfigContext } from "../../Context/configContext";

function ConsentRecordPage(): JSX.Element {
  const { config } = useContext(ConfigContext);
  const initialized = useRef(false);
  const [domainId ] = useState<any | null>(localStorage.getItem('activeDomainId'));
  const [cookieData, setCookieData] = useState<any>({});
  const [groupedCookies, setGroupedCookies] = useState<any[]>([]);
  const [scanDate, setScanDate] = useState<any>("");

  const consentService = new ConsentService(config!);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDomainScanDataById();
    }
    console.log("test");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  const getDomainScanDataById = () => {
    if (domainId) {
      const response = consentService.getDomainScanDataById(domainId);
      response.then((res) => {
        if (res?.data) {
          setCookieData(res?.data);
          const lastScanTimestamp = res?.data?.lastScan?.$date?.$numberLong;
          if (lastScanTimestamp) {
            const theDate = new Date(parseInt(lastScanTimestamp));
            setScanDate(formatDate(theDate)); // Correctly formatted scan date
          } else {
            setScanDate("No scan date available");
          }
          setGroupedCookies(groupCookiesByCategory(res?.data?.allCookies));
        }
      });
    }
  };

  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zeros if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const groupCookiesByCategory = (cookies) => {
    return cookies.reduce((acc, cookie) => {
      if (!acc[cookie.category]) {
        acc[cookie.category] = [];
      }
      acc[cookie.category].push(cookie);
      return acc;
    }, {});
  };

  const formatDuration = (expiryTimestamp) => {
    const now = Date.now() / 1000; // Current time in seconds
    const secondsLeft = expiryTimestamp - now;

    if (secondsLeft <= 0) {
      return "Expired";
    }

    const days = Math.floor(secondsLeft / 86400); // 1 day = 86400 seconds
    const years = Math.floor(days / 365);
    const remainingDays = days % 365;

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} and ${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""}`;
    } else {
      const hours = Math.floor(secondsLeft / 3600); // 1 hour = 3600 seconds
      return `${hours} hour${hours > 1 ? "s" : ""}`;
    }
  };

  return (
    <>
      {cookieData && (
        <Container fluid>
          <Card shadow="sm" padding="lg" radius="lg">
            <Flex justify="center" align="flex-start">
              <Image width={59} h={59} src={logo} />
            </Flex>
            <Divider my="xs" />
            <Center>
              <Stack gap={4}>
                <Title order={3}>Cookie Report for </Title>
                <Title order={4}>{cookieData.domainUrl}</Title>
              </Stack>
            </Center>
            <Card shadow="sm" withBorder mt="sm" mb="sm">
              <Title order={3} mb="sm">Summary</Title>
              <Text><strong>Scan Date: </strong> {scanDate}</Text>
              <Text><strong>Server Location:</strong> India</Text>
              <Text><strong>Total Cookies Identified:</strong> {cookieData.totalCookies}</Text>
            </Card>
            <Card shadow="sm" withBorder mt="sm" mb="sm">
              <Title order={3} mb="sm">Cookie Categories</Title>
              <Accordion variant="separated" multiple defaultValue={["Marketing"]}>
                {Object.keys(groupedCookies).map((category) => (
                  <Accordion.Item key={category} value={category}>
                    <Accordion.Control>
                      {category} Cookies ({groupedCookies[category].length})
                    </Accordion.Control>
                    <Accordion.Panel style={{ background: "#f8f9fa" }}>
                      {groupedCookies[category].map((cookie, index) => (
                        <Card shadow="sm" withBorder mt="sm" radius="md" key={index}>
                          <Stack p="xs" gap={5}>
                            <Text size="sm"><strong>Cookie Name :</strong> {cookie.name}</Text>
                            <Text size="sm"><strong>Description :</strong> {cookie.description}</Text>
                            <Text size="sm"><strong>Provider :</strong> {cookie.provider}</Text>
                            <Text size="sm"><strong>Type :</strong> {cookie.typeOf}</Text>
                            <Text size="sm"><strong>Expiry :</strong>&nbsp; {formatDuration(cookie.expiry)}</Text>
                            <Text size="sm"><strong>First Found URL :</strong>&nbsp; {cookie.path || "-"}</Text>
                            <Text size="sm"><strong>Initiator :</strong>&nbsp; {cookie.path || "-"}</Text>
                            <Text size="sm"><strong>Source URL :</strong>&nbsp; {cookie.sourceUrl || "-"}</Text>
                          </Stack>
                        </Card>
                      ))}
                    </Accordion.Panel>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card>
          </Card>
        </Container>
      )}
    </>
  );
}

export { ConsentRecordPage };

import {
  Grid,
  Paper,
  Text,
  Title,
  Group,
  Stack,
  Button,
  Center,
  BackgroundImage,
  SimpleGrid,
  Select,
  Accordion,
  Radio,
  Image,
  Checkbox,
  Divider,
  Tooltip,
  MultiSelect,
  em
} from "@mantine/core";
import {
  IconShieldLock,
  IconCircleCheckFilled,
  IconLayout,
  IconPalette,
  IconUpload,
  IconChevronDown,
  IconChevronUp,
  IconInfoCircle,
  IconLanguage,
} from "@tabler/icons-react";
import React, { useContext, useEffect, useState } from "react";
import colorgradient from "../../../images/colorgradient.svg";
import { Dropzone, MIME_TYPES, FileWithPath } from "@mantine/dropzone";
import { Banner } from "./Banner";
import { BannerTheme } from "./BannerTheme";
import { ButtonTheme } from "./ButtonTheme";
import { ThemeDetails } from "../../../Models/themeDetails";
import { ConsentOptionsConfig } from "../../../Models/consentOptionsConfig";
import { AdditionalDetailsConfig } from "../../../Models/additionalDetailsConfig";
import { ConsentConfig } from "../../../Models/consentConfig";
import './consent.css';
import { ConsentService } from "../../../services/consentService";
import { useMediaQuery } from '@mantine/hooks';
import { ConfigContext } from "../../../Context/configContext";

const consentOptionsData : ConsentOptionsConfig[] =  [{checked: true,label: "Necessary"},{checked: false,label: "Preferences"},{checked: false,label: "Statistics"},{checked: false,label: "Marketing"}];
const themesConfig: ThemeDetails[] = [
    {background:"#fff",type:"Light",buttonStyle:"Mixed",textColor:"#000",highlight:"#4CAF50",buttonCustomizeBackGround:"#0078b4",buttonCustomizeTextColor:"#fff",buttonCustomizeVariant:"filled",buttonCustomizeBorder:"#0078b4",buttonRejectBackGround:"#eaeaea",buttonRejectTextColor:"#000",buttonRejectVariant:"filled",buttonRejectBorder:"#0078b4",buttonAcceptBackGround:"#0078b4",buttonAcceptTextColor:"white",buttonAcceptVariant:"filled",buttonAcceptBorder:"#0078b4",consentOptions:consentOptionsData},
    {background:"#000",type:"Dark",buttonStyle:"Mixed",textColor:"#fff",highlight:"gray",buttonCustomizeBackGround:"#fff",buttonCustomizeTextColor:"#000",buttonCustomizeVariant:"filled",buttonCustomizeBorder:"#fff",buttonRejectBackGround:"#fff",buttonRejectTextColor:"#000",buttonRejectVariant:"filled",buttonRejectBorder:"#fff",buttonAcceptBackGround:"#fff",buttonAcceptTextColor:"#000",buttonAcceptVariant:"filled",buttonAcceptBorder:"#fff",consentOptions:consentOptionsData}
]
const additionalOptions: AdditionalDetailsConfig = {isPreferences:false,isStatistics:false,isMarketing:false,isDisplayCloseIcon:false}
const finalConsentConfig: ConsentConfig = {language:'en', layout:'Box',alignment:'leftBottomPopUp',theme:themesConfig[0],isLogoDisplay:true,logo:'',method:'',type:'',additionalDetailsConfig:additionalOptions, baseUrl: window.location.hostname};
const visitorData = ["All Visitors", "EU Only", "Africa", "Americas", "Asia", "Europe", "Oceania"];

const languageOptions = [{label:'Hindi',value:'hi'},{label:'Bengali',value:'bn'},{label:'Telugu',value:'tg'},{label:'Marathi',value:'mr'},{label:'Tamil',value:'ta'},{label:'Urdu',value:'ur'},{label:'Gujarati',value:'gr'},{label:'Kannada',value:'kn'},{label:'English',value:'en'},{label:'Odisha',value:'od'},{label:'Malayalam',value:'ml'},{label:'Punjabi',value:'pb'},{label:'Assam',value:'as'}];

declare var Cookiex: {
  new (): {
      init(theme: { domainId: string; domainName: string }): void;
  };
};

function Step5({ forward, backward, screenSize, consentData, consentConfigData, domainId, domainName}): JSX.Element {
  const { config } = useContext(ConfigContext);
  const [layoutType, setLayoutType] = useState("Box");
  const [themeType, setThemeType] = useState("Light");
  const [buttonStyle, setButtonStyle] = useState("Mixed");
  const [bannerValue, setBannerValue] = useState("leftBottomPopUp");
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [tempFile, setTempFile] = useState<FileWithPath[]>([]);
  const [isLogoShown, SetLogoShown] = useState(true);
  const [language, setLanguage] = useState('en');
  const [isAdditionalDetails, setAdditionalDetails] = useState(true);
  const [customThemeStyle, setCustomThemeStyle] = useState<ThemeDetails>(finalConsentConfig.theme);
  const [consentConfig, setConsentConfig] = useState<ConsentConfig>(finalConsentConfig);
  const [selectedOptions, setAdditionalDetailsOptions] = useState<string[]>(['Necessary']);
  const [complianceMethod, setComplianceMethod] = useState('Explicit Consent');
  const [complianceType, setComplianceType] = useState("Multilevel");
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const consentService = new ConsentService(config!);

  useEffect(() => {
       if(consentConfigData?.theme) {
           console.log(consentConfigData)
           setCustomThemeStyle(consentConfigData.theme);
           setConsentConfig(consentConfigData);
           setComplianceMethod(consentConfigData.method);
           setComplianceType(consentConfigData.type);
           setAdditionalDetailsOptions(consentConfigData.theme.consentOptions.filter(opt => opt.checked).map(opt => opt.label));
           setLayoutType(consentConfigData.layout);
           setBannerValue(consentConfigData.alignment);
           setThemeType(consentConfigData.theme.type);
           setButtonStyle(consentConfigData.theme.buttonStyle);
           SetLogoShown(consentConfigData.isLogoDisplay);
           setLanguage(consentConfigData.language);
       }
       setTempFile([]);
       console.log(tempFile);
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generatePreview = (isPreview) => {
      consentConfig.layout = layoutType;
      consentConfig.theme = customThemeStyle;
      consentConfig.alignment = bannerValue;
      consentConfig.isLogoDisplay = isLogoShown;
      consentConfig.method = complianceMethod;
      consentConfig.type = complianceType;
      consentConfig.theme = customThemeStyle;
      consentConfig.language = language;
      if(files.length > 0) {
        const imageUrl = URL.createObjectURL(files[0]);
        consentConfig.logo = imageUrl;
      }
      populateOptions(selectedOptions);

      if(isPreview) {
        generateHTML();
      }
  };

  const handleLanguage = (languageObj) => {
    setLanguage(languageObj);
  };

  const generateHTML = () => {
      consentData(consentConfig, "styles");

      const loadExternalScript = (src) => {
        return new Promise<void>((resolve, reject) => {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = src;

          script.onload = () => {
              resolve();
          };

          script.onerror = () => {
              reject(new Error(`Failed to load script ${src}`));
          };

          document.head.appendChild(script);
        });
      }

      const scriptUrl = config?.bannerUrl + '/cookiex.min.js';

      setTimeout(function () {
        loadExternalScript(scriptUrl)
        .then(() => {
            const theme = {
                domainId: domainId,
                domainName: domainName
            };

            const cookiex = new Cookiex();
            cookiex.init(theme);
        })
        .catch((error) => {
            console.error('Error loading external script:', error);
        });
      }, 1000);
  };

  const populateOptions = (additionalDetailsOptions) => {
    setAdditionalDetailsOptions(additionalDetailsOptions);
     customThemeStyle.consentOptions.forEach((prevOption) => {
        prevOption.checked = false;
        additionalDetailsOptions.forEach((selectOption) => {
           if(prevOption.label == selectOption) {
              prevOption.checked = true;
           }
        })
     });
     setTempFile([]);
  };

  const handleCustomThemeStyles = (styleType) => {
    if(styleType != 'Custom') {
        const filteredTheme = themesConfig.filter(theme => theme.type === styleType);
        setCustomThemeStyle(filteredTheme[0]);
    } else {
        setCustomThemeStyle({background:"#fff",type:"Custom",buttonStyle:"Mixed", textColor: "#000",highlight:"#228be6",buttonCustomizeBackGround:"#228be6",buttonCustomizeTextColor:"#fff",buttonCustomizeVariant:"filled",buttonCustomizeBorder:"#228be6",buttonRejectBackGround:"#228be6",buttonRejectTextColor:"#fff",buttonRejectVariant:"filled",buttonRejectBorder:"#228be6",buttonAcceptBackGround:"#228be6",buttonAcceptTextColor:"#fff",buttonAcceptVariant:"filled",buttonAcceptBorder:"#228be6", consentOptions:consentOptionsData});
    }
    setTempFile([]);
  };

  const handleButtonStyles = (styleType) => {
     const tempObj = customThemeStyle;
     setButtonStyle(styleType);
     if(styleType == 'Mixed') {
        tempObj.buttonCustomizeVariant = "filled";
        tempObj.buttonAcceptVariant = "filled";
        tempObj.buttonRejectVariant = "filled";
     } else if (styleType == 'Outline') {
        tempObj.buttonCustomizeVariant = "outline";
        tempObj.buttonAcceptVariant = "outline";
        tempObj.buttonRejectVariant = "outline";
     } else {
        tempObj.buttonCustomizeVariant = "filled";
        tempObj.buttonAcceptVariant = "filled";
        tempObj.buttonRejectVariant = "filled";
     }

     let textColor = "#000";
     if(customThemeStyle.type == "Dark" && styleType == 'Outline') {
        textColor = "#fff";
     }

     if(customThemeStyle.type == "Light" && styleType == 'Outline') {
         textColor = "#0078b4";
     } else if(customThemeStyle.type == "Light" && styleType != 'Outline') {
         textColor = "#fff";
     }

     tempObj.buttonCustomizeTextColor = textColor;
     tempObj.buttonAcceptTextColor = textColor;
     tempObj.buttonRejectTextColor = textColor;
     tempObj.buttonStyle = styleType;
     setCustomThemeStyle(tempObj);
     setTempFile([]);
  };

  const handleCustomStyles = (colorValue, type) => {
     const tempObj = customThemeStyle;
     if(type == 'Background') {
        tempObj['background'] = colorValue;
     } else if (type == 'Text') {
        tempObj['textColor'] = colorValue;
     } else {
        tempObj['highlight'] = colorValue;
     }
     setCustomThemeStyle(tempObj);
     setTempFile([]);
  };

  const handleCustomButtonStyles = (colorValue, buttonType, type) => {
        const tempObj = customThemeStyle;
        if(buttonType == 'buttonCustomize') {
            if(type == 'Background') {
                tempObj['buttonCustomizeBackGround'] = colorValue;
             } else if (type == 'Text') {
                tempObj['buttonCustomizeTextColor'] = colorValue;
             } else {
                tempObj['buttonCustomizeBorder'] = colorValue;
             }
        } else if(buttonType == 'buttonReject') {
           if(type == 'Background') {
               tempObj['buttonRejectBackGround'] = colorValue;
            } else if (type == 'Text') {
               tempObj['buttonRejectTextColor'] = colorValue;
            } else {
               tempObj['buttonRejectBorder'] = colorValue;
            }
        } else if(buttonType == 'buttonAccept') {
            if(type == 'Background') {
                tempObj['buttonAcceptBackGround'] = colorValue;
             } else if (type == 'Text') {
                tempObj['buttonAcceptTextColor'] = colorValue;
             } else {
                tempObj['buttonAcceptBorder'] = colorValue;
             }
        }
        setCustomThemeStyle(tempObj);
        setTempFile([]);
  };

  const handleComplianceMethod = (selectedType) => {
     setComplianceMethod(selectedType);
  };

  const handleComplianceType = (selectedType) => {
     setComplianceType(selectedType);
  };

  const handleUpload = (file) => {
     setFiles(file);
     setTempFile(file);
     consentService.uploadClientLogo(domainId, file);
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return <Image w={50} key={index} src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} />;
  });

  const saveConsentBannerConfig = (isModal) => {
      generatePreview(false);
      consentData(consentConfig, "styles");
      if(isModal) {
        forward(6);
        screenSize("55%");
      }
  };

  return (
    <React.Fragment>
      <>
        {screenSize && (
            <Grid>
              <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                <Center>
                  <Stack>
                    <Title style={{ textAlign: "center" }} order={2}>Configure Your Consent Banner</Title>
                    <Text mb="xl" size="xs">Select a predefined banner template for the legislation you want to enable compliance for on your domain</Text>
                  </Stack>
                </Center>
              </Grid.Col>
            </Grid>
         )}
         <Grid>
          <Grid.Col span={{ base: 12, md: 5, lg: 3 }} style={{ borderRadius: "8px", background: screenSize?"#f2f4fa":"#fff" }}>
            <div>
              <Paper withBorder shadow="0">
                <Accordion transitionDuration={0} defaultValue="layout">
                  <Accordion.Item value="layout">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconLayout />
                        <Text size="xs"> Layout </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Text size="xs" mb="xs" fw={500}>Banner Layout{" "}</Text>
                      <SimpleGrid cols={3}>
                        <div>
                          <Paper shadow="0px" withBorder
                            p="lg" h="60" w="60" style={{ position: "relative" }}
                            onClick={(event) => {
                              setLayoutType("Box");
                              setBannerValue("leftBottomPopUp");
                            }}
                          >
                            <div
                              className="fill-primary"
                              style={{
                                border: "1px solid #eaeaea",
                                left: "7px",
                                bottom: "7px",
                                padding: "10px",
                                position: "absolute",
                                width: "40%",
                              }}
                            ></div>
                            {layoutType == "Box" && (
                              <IconCircleCheckFilled
                                className="primary"
                                size="1rem"
                                stroke={3}
                                style={{ left: "-8px", top: "-7px", position: "absolute" }}
                              />
                            )}
                          </Paper>
                          <Text size="xs" mt={5}>
                            Box
                          </Text>
                        </div>
                        <div>
                          <Paper
                            shadow="0px"
                            withBorder
                            p="lg"
                            h="60"
                            w="60"
                            style={{ position: "relative" }}
                            onClick={(event) => {
                              setLayoutType("Banner");
                              setBannerValue("bannerBottom");
                            }}
                          >
                            <div
                              className="fill-primary"
                              style={{
                                border: "1px solid #eaeaea",
                                left: "5px",
                                bottom: "7px",
                                padding: "5px",
                                position: "absolute",
                                width: "80%",
                              }}
                            ></div>
                            {layoutType == "Banner" && (
                              <IconCircleCheckFilled
                                className="primary"
                                size="1rem"
                                stroke={3}
                                style={{ left: "-8px", top: "-7px", position: "absolute" }}
                              />
                            )}
                          </Paper>
                          <Text size="xs" mt={5}>
                            Banner
                          </Text>
                        </div>
                        <div>
                          <Paper
                            shadow="0px"
                            withBorder
                            p="lg"
                            h="60"
                            w="60"
                            style={{ position: "relative" }}
                            onClick={(event) => {
                              setLayoutType("PopUp");
                              setBannerValue("popUpCenter");
                            }}
                          >
                            <div
                              className="fill-primary"
                              style={{
                                border: "1px solid #eaeaea",
                                left: "30%",
                                bottom: "30%",
                                padding: "10px",
                                position: "absolute",
                                width: "40%",
                              }}
                            ></div>
                            {layoutType == "PopUp" && (
                              <IconCircleCheckFilled
                                className="primary"
                                size="1rem"
                                stroke={3}
                                style={{ left: "-8px", top: "-7px", position: "absolute" }}
                              />
                            )}
                          </Paper>
                          <Text size="xs" mt={5}>
                            Popup
                          </Text>
                        </div>
                      </SimpleGrid>
                      {layoutType == "Box" && (
                        <Radio.Group mt={15} mb={15} value={bannerValue} onChange={setBannerValue}>
                          <SimpleGrid cols={2}>
                            <Radio size="xs" value="leftBottomPopUp" label="Bottom Left" />
                            <Radio size="xs" value="rightBottomPopUp" label="Bottom Right" />
                            <Radio size="xs" value="leftTopPopUp" label="Top Left" />
                            <Radio size="xs" value="rightTopPopUp" label="Top Right" />
                          </SimpleGrid>
                        </Radio.Group>
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="design">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconPalette />
                        <Text size="xs"> Design </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Select mt={10} label="Theme" allowDeselect={false} defaultValue={themeType} onChange={(event) => handleCustomThemeStyles(event)} data={["Light", "Dark", "Custom"]} />
                      <div>
                      {customThemeStyle?.type == 'Custom' && (
                        <div>
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.background} label="Background" description="" />
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.textColor} label="Text" description="Choose the color of all texts within the banner" />
                            <BannerTheme customStyles={handleCustomStyles} background={customThemeStyle.highlight} label="Highlight" description="Choose your highlight color that will impact all links and active toggles in your banner" />
                            <Text mt="sm" size="xs" ta="center" c="blue" onClick={() => handleCustomThemeStyles('Light')}>Reset all banner consent</Text>
                            <Divider my="sm" />
                            <Text mt="sm" size="xs" c="blue">Button Colors</Text>
                            <Accordion transitionDuration={0} variant="contained" mt={5}>
                              <Accordion.Item value="customize" mt={5}>
                                <Accordion.Control>
                                  <Group justify="left"><Text size="xs"> Button 1 (Customize) </Text></Group>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonCustomizeBackGround} label="Background" type="buttonCustomize" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonCustomizeTextColor} label="Text" type="buttonCustomize" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonCustomizeBorder} label="Border" type="buttonCustomize" description="" />
                                </Accordion.Panel>
                              </Accordion.Item>
                              <Accordion.Item value="reject" mt={5}>
                                <Accordion.Control>
                                  <Group justify="left">
                                    <Group justify="left"><Text size="xs"> Button 2 (Reject All) </Text></Group>
                                  </Group>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonRejectBackGround} label="Background" type="buttonReject" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonRejectTextColor} label="Text" type="buttonReject" description="" />
                                    <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonRejectBorder} label="Border" type="buttonReject" description="" />
                                </Accordion.Panel>
                              </Accordion.Item>
                              <Accordion.Item value="accept" mt={5}>
                              <Accordion.Control>
                                <Group justify="left">
                                  <Group justify="left"><Text size="xs"> Button 2 (Accept) </Text></Group>
                                </Group>
                              </Accordion.Control>
                              <Accordion.Panel>
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonAcceptBackGround} label="Background" type="buttonAccept" description="" />
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonAcceptTextColor} label="Text" type="buttonAccept" description="" />
                                   <ButtonTheme customStyles={handleCustomButtonStyles} background={customThemeStyle.buttonAcceptBorder} label="Border" type="buttonAccept" description="" />
                              </Accordion.Panel>
                            </Accordion.Item>
                           </Accordion>
                        </div>
                      )}
                      {customThemeStyle?.type != 'Custom' && (
                        <Select mt={10} allowDeselect={false} label="Button Style" defaultValue={buttonStyle} onChange={(event) => handleButtonStyles(event)}  data={["Outline", "Mixed", "Solid"]} />
                      )}
                      </div>
                      <Group justify="left" mt={10}>
                          <Title order={6} fw={500}>Logo</Title>
                          <Tooltip label="Add a logo to display on your banner">
                            <IconInfoCircle size="14"/>
                          </Tooltip>
                      </Group>
                      <Checkbox mt={4}
                        mb={10}
                        checked={isLogoShown}
                        onChange={(event) => SetLogoShown(event.currentTarget.checked)}
                        label="Display logo on banner"
                      />
                      <Dropzone
                        maxFiles={1}
                        accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.svg, MIME_TYPES.gif]}
                        onDrop={handleUpload}
                      >
                        <Group justify="center">
                          <IconUpload size={15} color="blue" />
                          <Text size="xs" ta="center" c="blue">
                            Upload File
                          </Text>
                        </Group>
                      </Dropzone>
                      <Text size="xs" mt={10}>
                        Filetype jpg, jpeg, gif, svg
                      </Text>
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="compliance">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconShieldLock />
                        <Text size="xs"> Compliance </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      <div>
                        <Group justify="left" mt={7}>
                          <Title order={6} fw={500}>Method</Title>
                          <Tooltip multiline w={220} label="With explicit consent, the cookie banner will keep showing on each page until the visitor has provided a consent choice. With implicit consent the user has the option to ignore the banner and continue to use the website without giving consent. Please check with your Data Protection Officer whether you can use this option">
                            <IconInfoCircle size="14"/>
                         </Tooltip>
                        </Group>
                        <Select mt={7} allowDeselect={false} defaultValue={complianceMethod} onChange={(event) => handleComplianceMethod(event)}  data={["Explicit Consent", "Implied Consent"]} />
                        <Accordion transitionDuration={0} defaultValue="type" variant="contained" mt={10}>
                          <Accordion.Item value="type">
                            <Accordion.Control>
                              <Group justify="left">
                                <Text size="xs"> Type </Text>
                              </Group>
                            </Accordion.Control>
                            <Accordion.Panel>
                              <Select allowDeselect={false} defaultValue={complianceType} onChange={(event) => handleComplianceType(event)} data={["Multilevel", "Accept Only", "Accept/Decline", "Inline multilevel", "Do not sell or share (opt in)"]} />
                              <Group>
                              {isAdditionalDetails && (
                                 <Group mt="10">
                                    <Text size="xs" c="blue" onClick={() => {setAdditionalDetails(false);}}>Show Additional Details</Text>
                                    <IconChevronDown color="blue" size="13" />
                                 </Group>
                              )}
                              {!isAdditionalDetails && (
                                 <div>
                                     <Group mt="10">
                                        <Text size="xs" c="blue"  onClick={() => {setAdditionalDetails(true);}}>Hide Additional Details</Text>
                                        <IconChevronUp color="blue" size="13" />
                                     </Group>
                                     <Text mt="3" size="xs">Default mode for checkboxes</Text>
                                     <Checkbox.Group value={selectedOptions} onChange={populateOptions}>
                                        <Stack mt="xs">
                                         {customThemeStyle.consentOptions.map((option, index) => (
                                            option.label != 'Necessary' && (
                                             <Group>
                                                 <Checkbox size="xs" value={option.label} color={customThemeStyle.highlight} checked={option.checked}  label={option.label} />
                                             </Group>
                                            )
                                         ))}
                                        </Stack>
                                     </Checkbox.Group>
                                 </div>
                              )}
                              </Group>
                            </Accordion.Panel>
                          </Accordion.Item>
                        </Accordion>
                        <Text size="xs" fw={500} mt={10}>
                          Distribution{" "}
                        </Text>
                        <Text size="xs" fw={200} mb="xs">
                          Select territory for visitors who will be asked for consent.
                        </Text>
                         <MultiSelect
                           checkIconPosition="left"
                           data={visitorData}
                           pb={150}
                           defaultValue={["All Visitors"]}
                         />
                        </div>
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="content">
                    <Accordion.Control style={{ borderBottom: "1px solid #f2f4fa" }}>
                      <Group justify="left">
                        <IconLanguage />
                        <Text size="xs"> Content </Text>
                      </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                      {languageOptions.length > 0 && (
                          <Select mt={10} allowDeselect={false} defaultValue={language} label="Language" data={languageOptions} onChange={(event) => handleLanguage(event)} />
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </Paper>
            </div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 7, lg: 9 }}>
            <div style={{ width: "100%" }}>
              <BackgroundImage w="100%" mih={450} src={colorgradient} radius="sm">
                <Grid>
                  <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                    {layoutType == "Box" && (
                     <Paper w={isMobile?"80%":"50%"} radius="md" shadow="0px" withBorder className={bannerValue} bg={customThemeStyle.background}>
                        {isLogoShown && <div>{previews}</div>}
                        <Banner customThemeStyle={customThemeStyle} populateOptions={populateOptions} selectedOptions={selectedOptions} />
                      </Paper>
                    )}

                    {layoutType == "Banner" && (
                      <Paper radius="md" w="auto" shadow="0px" withBorder p="md" className="bannerBottom" bg={customThemeStyle.background}>
                        {isLogoShown && <div>{previews}</div>}
                        <Banner customThemeStyle={customThemeStyle} populateOptions={populateOptions}  selectedOptions={selectedOptions} />
                      </Paper>
                    )}

                    {layoutType == "PopUp" && (
                      <Paper w={isMobile?"80%":"50%"} radius="md" shadow="0px" withBorder p="md" className="popUpCenter" bg={customThemeStyle.background}>
                        {isLogoShown && <div>{previews}</div>}
                        <Banner customThemeStyle={customThemeStyle} populateOptions={populateOptions} selectedOptions={selectedOptions} />
                      </Paper>
                    )}
                  </Grid.Col>
                </Grid>
              </BackgroundImage>
            </div>
            {screenSize && (
            <Group mt={20}>
                <Button
                  w={120}
                  variant="outline"
                  size="sm"
                  onClick={(event) => {
                    backward(4);
                    screenSize("55%");
                  }}
                >
                  Back
                </Button>
                <Button w={120} variant="outline" size="sm" color="blue"onClick={(event) => {generatePreview(true);}}>Preview</Button>
                <Button
                  w={120}
                  variant="filled"
                  size="sm"
                  onClick={(event) => {
                    saveConsentBannerConfig(true);
                  }}
                >
                  Next
                </Button>
              </Group>
              )}
              {!screenSize && (
                <Group>
                   <Button w={120} mt="xs" variant="outline" size="sm" color="blue"onClick={(event) => {generatePreview(true);}}>Preview</Button>
                   <Button variant="filled" mt="xs" size="sm" onClick={(event) => {saveConsentBannerConfig(false);}}>
                      Publish Changes
                   </Button>
               </Group>
              )}
          </Grid.Col>
        </Grid>
      </>
    </React.Fragment>
  );
}

export { Step5 };

import axios from 'axios';
import { getAuthorizationHeader } from "../utils/getAuthorizationHeader";
import { Config } from '../utils/types';

export class PricingService {
  private config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  public getPlans() {
    const url = this.config.apiBaseUrl + "/plans";
    try {
      return axios.get(`${url}`,
          {headers: getAuthorizationHeader()}
      );
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async savePlan(data: any, domainId:any): Promise<any>  {
    const url = this.config.apiBaseUrl + "/subscriptions/";
    try {
      const response = await axios.post(`${url}${domainId}`,
          data,
          {headers: getAuthorizationHeader()}
      );
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

};
